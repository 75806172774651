var Tegeldepot = Tegeldepot || {};

Tegeldepot.Slider = class {
    constructor(options) {
        this.initSlider = this.initSlider.bind(this);

        this.options = jQuery.extend({}, {
            carouselSelector: '.slider',
            thumbsSelector: false,
            showSlides: 1,
            showDots: false,
            showArrows: false,
            autoplay: false,
            fade: false,
            autoplaySpeed: 0
        }, options);

        if (this.options.thumbsSelector) {
            this.initThumbSlider();
        } else {
            this.initSlider();
        }
    }

    initSlider() {
        const showSlides = this.options.showSlides;
        const showDots = this.options.showDots;
        const showArrows = this.options.showArrows;
        const autoplay = this.options.autoplay;
        const autoplaySpeed = this.options.autoplaySpeed;
        const fade = this.options.fade;

        jQuery(this.options.carouselSelector).not('.slick-initialized').slick({
            slidesToShow: showSlides,
            infinite: true,
            lazyLoad: 'ondemand',
            dots: showDots,
            arrows: showArrows,
            autoplay: autoplay,
            fade: fade,
            autoplaySpeed: autoplaySpeed,
        });
    }

    initThumbSlider() {
        const mainSlider = this.options.carouselSelector;
        const thumbSlider = this.options.thumbsSelector;
        const showSlides = this.options.showSlides;
        const showDots = this.options.showDots;
        const autoplay = this.options.autoplay;
        const autoplaySpeed = this.options.autoplaySpeed;
        const showArrows = this.options.showArrows;
        const fade = this.options.fade;

        jQuery(mainSlider).not('.slick-initialized').slick({
            slidesToShow: showSlides,
            infinite: true,
            lazyLoad: 'ondemand',
            dots: showDots,
            asNavFor: thumbSlider,
            arrows: showArrows,
            autoplay: autoplay,
            fade: fade,
            prevArrow: '<a href="#" class="slick-prev"><i class="ic-chevron-left"></i></a>',
            nextArrow: '<a href="#" class="slick-next"><i class="ic-chevron-right"></i></a>',
            autoplaySpeed: autoplaySpeed,
        });

        jQuery(thumbSlider).not('.slick-initialized').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
            focusOnSelect: true,
            asNavFor: mainSlider,
            arrows: true,
            prevArrow: '<a href="#" class="slick-prev"><i class="ic-chevron-left"></i></a>',
            nextArrow: '<a href="#" class="slick-next"><i class="ic-chevron-right"></i></a>',
            autoplay: autoplay,
            autoplaySpeed: autoplaySpeed,
        });
    }
};